import { newTracker, trackPageView, trackSelfDescribingEvent } from '@snowplow/browser-tracker';
import { datadogLogs } from '@datadog/browser-logs';
import { getStateFromDOM } from 'core/utils/client';
import { windowExists } from 'core/helpers/Window';
import { createEventLogger } from 'promoted-snowplow-logger';
import { v4 as uuidv4 } from 'uuid';
import cookie from 'js-cookie';

const hostname = windowExists ? window.location.hostname : undefined;
const snowplowLoggerUrlPrefix = windowExists ? getStateFromDOM('env.apiHost') : undefined;
const isProduction = windowExists && window.location.hostname === 'rvshare.com';

export const promotedLoggingEnabled = windowExists;
const platformName = 'rvshare'; // needs to be lowercase
const loggingApiId = isProduction ? 'RVshare-prod' : 'RVshare-dev';
const cookieSecure = isProduction;
const cookieSameSite = isProduction ? 'None' : 'Lax';

// Should not be used on the server-side.
if (windowExists) {
  newTracker('cf', snowplowLoggerUrlPrefix, {
    loggingApiId,
    cookieSecure,
    cookieSameSite,
    cookieDomain: hostname,
    postPath: '/v1/promoted_ai/track',
    contexts: {
      webPage: true,
      session: false,
    },
    maxLocalStorageQueueSize: 100,
    // Has other interesting options like postPath, stateStorageStrategy.
  });
}

// this logic should match lib/promoted_ai_search.rb#anonymous_id
const getAnonymousUserId = userId => {
  let anonymousId;

  // did we either...
  // 1) Log out since the last search (authenticated->unauthenticated)
  // 2) Switch users since the last search (authenticated->other authenticated)
  const loggedOutOrSwitchedUser =
    cookie.get('promoted_ai_last_user_id') &&
    userId?.toString() !== cookie.get('promoted_ai_last_user_id').toString();

  if (loggedOutOrSwitchedUser) {
    // if so, we want a new anonymous id to prevent collisions (two users having the same anon id)
    // we do it on logout instead of login to help users keep the same anon id throughout the funnel
    // (since they are more likely to start unauthenticated and end authenticated than vice-versa)
    anonymousId = uuidv4();
    // TODO remove this after we have determined cause of the duplicate anonymous ids
    datadogLogs.logger.info(
      `Generated new anonymous id: ${anonymousId} for user_id: ${userId || 'unauthenticated-user'}`,
      {
        tags: ['PromotedAI', 'anonymous_id'],
      }
    );
  } else {
    // for other cases (unauthenticated->authenticated, authenticated->same authenticated)
    // we want to retain the same anonymous id (or generate one if it doesn't exist)
    anonymousId = cookie.get('promoted_ai_anonymous_id') || uuidv4();
    // TODO remove this after we have determined cause of the duplicate anonymous ids
    if (!cookie.get('promoted_ai_anonymous_id')) {
      datadogLogs.logger.info(
        `Generated new anonymous id: ${anonymousId} for user_id: ${userId ||
          'unauthenticated-user'}`,
        {
          tags: ['PromotedAI', 'anonymous_id'],
        }
      );
    }
  }

  cookie.set('promoted_ai_anonymous_id', anonymousId, {
    expires: 365,
    domain: '.rvshare.com',
    secure: true,
  });
  cookie.set('promoted_ai_last_user_id', userId || '', {
    expires: 365,
    domain: '.rvshare.com',
    secure: true,
  });

  return anonymousId;
};

const getUserId = () => {
  const { currentUser } = getStateFromDOM();

  return currentUser?.id || currentUser?.user_id || null;
};

// Throw when developing.
const throwError = windowExists && !isProduction;
// eslint-disable-next-line no-console
export const handleError = throwError
  ? err => {
      throw err;
    }
  : console.error;

export const eventLogger = createEventLogger({
  enabled: promotedLoggingEnabled,
  platformName,
  handleError,
  snowplow: {
    trackSelfDescribingEvent,
    trackPageView,
  },
  // Optional - if you have custom anonymous userId.  This overrides the anonUserId on calls through eventLogger.
  // This does not work with auto tracking.
  getUserInfo: () => {
    const userId = getUserId();

    return {
      userId,
      anonUserId: getAnonymousUserId(userId),
    };
  },
});
